<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'creator-file',
  components: {
    BaseIcon,
  },
  props: {
    attachment: {
      type: Object,
      default: () => ({}),
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="flex h-32 w-32 flex-col overflow-hidden rounded-md shadow-sm">
    <div
      class="relative flex flex-1 cursor-pointer items-center justify-center overflow-hidden object-fill"
    >
      <div
        v-if="removable"
        class="absolute inset-0 z-10 flex w-full items-center justify-center bg-white opacity-0 transition duration-300 ease-in-out hover:opacity-100"
        @click="$emit('delete', attachment)"
      >
        <base-icon class="text-fg-error" svg="plain/bin" size="2xl" />
      </div>

      <base-icon v-if="attachment.type === 'pdf'" svg="rec/pdf" size="2xl" />
      <div
        v-else-if="attachment.type === 'video'"
        class="flex h-full w-full items-center justify-center bg-fg-info"
      >
        <base-icon class="text-white" svg="plain/play" size="2xl" />
      </div>
      <img
        v-else-if="attachment.type === 'image'"
        :src="cdn(attachment.cdn).preview('250x140').quality('best').url()"
        class="h-full w-full object-cover"
      />
    </div>
    <div class="px-2.5 py-[5px] text-center text-sm">
      <div class="truncate font-bold">
        {{ attachment.name }}
      </div>
      <span class="text-fg-disabled">
        {{ attachment.readableSize }}
      </span>
    </div>
  </div>
</template>
